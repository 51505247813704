<template>
  <div class="elderlyCareAppointment">
    <div class="section-box">
      <div class="section-new">
        <div class="header" @click="clickCall">
          <img src="./img/banner.png" alt="" />
          <div class="opera">
            <div v-if="isJudge" class="myOrderJudge" @click.stop="toOrderJudge">
              <div class="myOrder-l">预约审核</div>
              <!-- <div class="myOrder-r">
              <img src="@/assets/img/tip.png" alt="" />
            </div> -->
            </div>
            <div class="myOrder" @click.stop="toOrder">
              <div class="myOrder-l">我的预约</div>
            </div>
          </div>
        </div>

        <div class="content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="item-left">
                <img
                  :src="item.roomPic ? item.roomPic.split(',')[0] : ''"
                  alt=""
                />
              </div>
              <div class="item-right">
                <div class="content-item--text">
                  {{ `${item.roomName}-${item.bedName}` }}
                </div>
                <div class="content-item--info">
                  {{ item.roomArea }}m²
                  <div class="line"></div>
                  {{ item.roomOrientation }}
                </div>
                <!-- <div class="content-item--detail">{{ item.content }}</div> -->
                <div class="content-item--operate">
                  <div class="operate-status">
                    {{ statusMap[item.rentalStatus] }}
                  </div>
                  <div class="operate-button">
                    <div
                      class="operate-button--status"
                      :style="item.rentalStatus | btnCss"
                    >
                      {{ item.rentalStatus | btnTxt }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toRegister } from "@/utils/common";
import { bedListPagingURL, getFunctionSettingUrl } from "./api";
import { statusMap, btnStatusMap } from "./map";

export default {
  name: "elderlyCareAppointment",
  data() {
    return {
      phone: "15290091901",
      statusMap,
      /* v-list 通用 */
      // isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   roomArea: "35m²",
        //   roomOrientation: "北",
        //   roomPic: "",
        //   userName: "刘月",
        //   isAnonymity: 1, // 是否匿名
        //   isChosen: 2, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 1,
        //   rentalStatus: 1,
        // },
        // {
        //   title: "有的一楼业主",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   roomArea: "35m²",
        //   roomOrientation: "北",
        //   insertTime: "2023-2-28 10:30:47",
        //   roomPic: "",
        //   userName: "刘月",
        //   isAnonymity: 0, // 是否匿名
        //   isChosen: 1, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 2,
        //   rentalStatus: 1,
        // },
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   roomArea: "35m²",
        //   roomOrientation: "北",
        //   roomPic: "",
        //   userName: "刘月",
        //   isAnonymity: 0, // 是否匿名
        //   isChosen: 0, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 2,
        //   rentalStatus: 1,
        //   audioTxt: "议事内容涉及居民隐私，不支持发表议事",
        // },
      ], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      isJudge: true,
      /** end */
    };
  },
  filters: {
    btnTxt(status) {
      return btnStatusMap[status];
    },
    btnCss(status) {
      let btnStatusCSS = {
        0: {
          color: "#FFFFFF",
          background: "#138347",
        },
        1: {
          color: "#FFFFFF",
          background: "#138347",
        },
        2: {
          color: "#FFFFFF",
          background: "#138347",
        },
      };
      return btnStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "mobile", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    this.getFunctionSetting();
  },
  methods: {
    toOrderJudge() {
      this.$router.push({
        name: "elderlyCareAppointmentAudioOrder",
      });
    },
    toOrder() {
      this.$router.push({
        name: "elderlyCareAppointmentMyOrder",
      });
    },
    async clickCall() {
      // if (await toRegister(this.$route.path, this.$route.query, "养老预约")) {
      window.location.href = `tel:${this.phone}`;
      // }
    },
    // 点击标题跳转议题详情页
    async toDetail(item) {
      if (await toRegister(this.$route.path, this.$route.query, "养老预约")) {
        this.$router.push({
          name: "elderlyCareAppointmentDetail",
          query: {
            id: item.id,
          },
        });
      }
    },
    async getFunctionSetting() {
      let res = await this.$axios.get(getFunctionSettingUrl, {
        params: {
          applicationName: "养老审核",
          functionName: "有权限号码",
        },
      });
      if (res.code == 200) {
        let mobile = this.mobile;
        if (res.data.settingOn.indexOf(mobile) != -1) {
          this.isJudge = true;
        } else {
          this.isJudge = false;
        }
      }
    },
    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(bedListPagingURL, {
          params: {
            // houseId: this.houseId || this.communityId || undefined,
            tenantId: this.tenantId,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elderlyCareAppointment {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    // padding: 36px 30px;
    .section-new {
      .header {
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
      }
      .opera {
        position: absolute;
        right: 10px;
        bottom: 30px;
      }
      .myOrderJudge {
        padding: 10px 30px;
        background: #feecd4;
        border-radius: 30px;
        color: #ec926b;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .myOrder {
        padding: 10px 30px;
        background: #feecd4;
        border-radius: 30px;
        color: #ec926b;
        .myOrder-l {
          font-size: 40px;
          color: #ec926b;
          font-weight: 700;
        }
        .myOrder-r {
          width: 45px;
          height: 45px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .content {
        // padding: 34px 24px 0;
        border-radius: 0 0 16px 16px;
        .content-item {
          box-sizing: border-box;
          width: 100%;
          border-radius: 16px;
          padding: 48px 24px 40px;
          background: #ffffff;
          margin-top: 30px;
          display: flex;
          .item-left {
            width: 250px;
            min-width: 250px;
            display: flex;
            align-items: center;
            margin-right: 26px;
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 100%;
              height: 250px;
              vertical-align: middle;
              object-fit: cover;
            }
          }
          .item-right {
            width: calc(100% - 276px);
            .content-item--text {
              font-weight: bold;
              line-height: 76px;
              font-size: 52px;
              color: rgba(0, 0, 0, 0.85);
              &:extend(.dhsl);
            }
            .content-item--info {
              width: 100%;
              font-size: 48px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 68px;
              // display: flex;
              // align-items: center;
              // &:extend(.dhsl-1);
              // white-space: normal;
              // word-break: break-all;
              // word-wrap: break-word;
              &:extend(.dhsl-1);
              > .line {
                box-sizing: border-box;
                display: inline-block;
                vertical-align: middle;
                height: 68px;
                // height: 100%;
                padding: 12px 40px 16px 24px;
                overflow: hidden;
                &::before {
                  content: "";
                  display: block;
                  width: 6px;
                  min-width: 6px;
                  height: 40px;
                  background: rgba(0, 0, 0, 0.85);
                  border-radius: 2px;
                }
              }
            }
            .content-item--operate {
              margin: 28px 0 0px;
              display: flex;
              align-items: flex-end;
              .operate-status {
                flex: 1;
                color: rgba(0, 0, 0, 0.5);
                font-size: 44px;
              }
              .operate-button {
                display: flex;
                justify-content: flex-end;
                .operate-button--status {
                  border-radius: 10px;
                  white-space: nowrap;
                  font-size: 44px;
                  line-height: 60px;
                  padding: 12px 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
